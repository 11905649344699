<template>
  <div class="finishPublishHomework">
    <div class="flex-box">
      <div class="left">
        <el-button type="success"
                   @click="addHomework">+新增作业</el-button>
      </div>
      <div class="flex-box">
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-query-frame"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <!-- <el-date-picker v-model="queryStartTime"
                        type="date"
                        class="picker"
                        placeholder="开始时间"
                        value-format="yyyy-MM-dd" />
        <el-date-picker v-model="queryEndTime"
                        type="date"
                        class="picker"
                        style="margin-left: 10px;"
                        placeholder="结束时间"
                        value-format="yyyy-MM-dd" /> -->
        <el-input placeholder="关键字"
                  class="picker"
                  v-model="queryKey"></el-input>
        <el-button type="primary"
                   class="picker"
                   @click="loadFinishPublishJob(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="finishPublishHomeworkTable">
      <el-table-column label="课程"
                       prop="course"
                       align="center"></el-table-column>
      <el-table-column label="班级"
                       prop="cName"
                       align="center"></el-table-column>
      <el-table-column label="作业标题"
                       prop="title"
                       align="center"></el-table-column>
      <el-table-column label="关键字"
                       prop="keyword"
                       align="center"></el-table-column>
      <el-table-column label="开始时间"
                       prop="begin_time"
                       align="center"></el-table-column>
      <el-table-column label="结束时间"
                       prop="end_time"
                       align="center"></el-table-column>
      <el-table-column label="附件"
                       align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px"
                    v-if="scope.row.url"
                    :src="scope.row.photoUrl"
                    :preview-src-list="scope.row.photoUrlList">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="需要提交"
                       prop="type"
                       align="center">
        <template slot-scope="props">
          <el-tag :type="props.row.type===2?'success':'warning'">
            {{props.row.type===2?'是':'否'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="备注"
                       prop="remark"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="150px">
        <template slot-scope="scope">
          <el-button type="primary"
                     size="mini"
                     @click="editHomework(scope.row)">编辑
          </el-button>
          <el-button type="danger"
                     size="mini"
                     @click="deletePublishHomework(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadFinishPublishJob"></el-pagination>
    <!-- 添加与编辑弹出框 -->
    <el-dialog :title="homeworkForm.id ? '编辑作业' : '添加作业'"
               :visible.sync="addHomeworkDialogVisible"
               width="600px">
      <div class="center">
        <el-form :model="homeworkForm"
                 ref="homeworkFormRef"
                 label-width="170px"
                 :rules="homeWorkRules">
          <el-form-item label="课程："
                        prop="courseIddAndClassId">
            <teacher-class-selector :checkDisabled="checkDisabled"
                                    @change="getClassId"
                                    :initialValue="homeworkForm.courseIddAndClassId" />
          </el-form-item>
          <el-form-item label="作业标题："
                        prop="title">
            <el-input :disabled="checkDisabled"
                      v-model="homeworkForm.title"></el-input>
          </el-form-item>
          <el-form-item label="关键字："
                        prop="keyword">
            <el-input :disabled="checkDisabled"
                      v-model="homeworkForm.keyword"></el-input>
          </el-form-item>
          <el-form-item label="课程简介："
                        prop="remark">
            <el-input :disabled="checkDisabled"
                      v-model="homeworkForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="开始时间："
                        prop="beginTime">
            <el-date-picker :disabled="checkDisabled"
                            v-model="homeworkForm.beginTime"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间："
                        prop="endTime">
            <el-date-picker :disabled="checkDisabled"
                            v-model="homeworkForm.endTime"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否提交完成后作业："
                        prop="type">
            <el-switch :disabled="checkDisabled"
                       v-model="homeworkForm.type"
                       active-text="是"
                       inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="附件">
            <el-upload action="/api/base/uploadByOss.do"
                       :headers="{Authorization:token}"
                       :limit="1"
                       ref="upload"
                       :disabled="checkDisabled"
                       :on-remove="onRemoveFile"
                       :on-success="enclosureUploadSuccess"
                       :data="{path:'finishPublishHomework',name:''}"
                       :show-file-list="true">
              <el-button type="primary"
                         icon='el-icon-upload2'>导 入</el-button>
            </el-upload>
            <el-button type="primary"
                       class="load-file"
                       @click="loadFile"
                       v-if="homeworkForm.file">下载附件</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addHomeworkDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addHomeworkConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看弹出框 -->
  </div>
</template>

<script>
import teacherClassSelector from "@/components/selectors/teacherClassSelector";

export default {
  components: { teacherClassSelector },
  name: "finishPublishHomework",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      finishPublishHomeworkTable: [],
      addHomeworkDialogVisible: false,
      queryTime: "",
      homeworkForm: {
        id: "",
        title: "",
        keyword: "",
        remark: "",
        beginTime: "",
        endTime: "",
        type: false,
        courseIddAndClassId: "",
        file: "",
      },
      homeWorkRules: {
        courseIddAndClassId: [{ required: true }],
        title: [{ required: true }],
        keyword: [{ required: true }],
        remark: [{ required: true }],
        beginTime: [{ required: true }],
        endTime: [{ required: true }],
        type: [{ required: true }],
      },
      switchValue: false,
      checkDisabled: false,
      queryStartTime: "",
      queryEndTime: "",
      queryKey: "",
      token: sessionStorage.getItem("token"),
    };
  },
  created() {
    this.loadFinishPublishJob(1);
  },
  methods: {
    loadFinishPublishJob(page) {
      // console.log(this.queryTime)
      // return

      const that = this;
      // let data = {
      //   begin_time: this.queryStartTime || "",
      //   end_time: this.queryEndTime || "",
      //   name: this.queryKey || "",
      //   indexNo: page,
      // };
      let data = {
        name: this.queryKey || "",
        indexNo: page,
      };
      if (this.queryTime) {
        data.begin_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      this.$post("common/queryHomework.do", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          let homework = res.data.rows;
          homework.forEach((row) => {
            row.photoUrl = that.$fileUploadDomain + row.url;
            row.photoUrlList = [];
            row.photoUrlList.push(that.$fileUploadDomain + row.url);
          });
          that.finishPublishHomeworkTable = homework;
          console.log(that.finishPublishHomeworkTable)
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    addHomework() {
      this.addHomeworkDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
        this.$refs["homeworkFormRef"].resetFields();
        this.homeworkForm.id = "";
        this.homeworkForm.file = "";
      });
      this.checkDisabled = false;
    },
    getClassId(event) {
      this.homeworkForm.courseIddAndClassId = event;
    },
    addHomeworkConfirm() {
      const that = this;
      this.$refs["homeworkFormRef"].validate((validate) => {
        if (validate) {
          this.$confirm("是否确认该操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let couserAndClass =
                this.homeworkForm.courseIddAndClassId.split("-");
              let data = {
                courseid: Number(couserAndClass[0]),
                classid: Number(couserAndClass[1]),
                title: this.homeworkForm.title,
                remark: this.homeworkForm.remark,
                keyword: this.homeworkForm.keyword,
                type: this.homeworkForm.type ? 2 : 1,
                begin_time: this.homeworkForm.beginTime,
                end_time: this.homeworkForm.endTime,
                url: this.homeworkForm.file,
              };
              let apiUrl = "/common/insertHomework.do";
              if (this.homeworkForm.id !== "") {
                data.id = this.homeworkForm.id;
                apiUrl = "/common/updateHomework.do";
              }
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.addHomeworkDialogVisible = false;
                  that.loadFinishPublishJob(that.page.currentPage);
                })
                .catch((err) => {
                  that.$message.error(err.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    editHomework(row) {
      this.addHomeworkDialogVisible = true;
      this.$nextTick(() => {
        this.homeworkForm.title = row.title;
        this.homeworkForm.keyword = row.keyword;
        this.homeworkForm.id = row.id;
        this.homeworkForm.remark = row.remark;
        this.homeworkForm.beginTime = row.begin_time;
        this.homeworkForm.endTime = row.end_time;
        this.homeworkForm.courseIddAndClassId = `${row.courseid}-${row.classid}`;
        this.homeworkForm.type = row.type === 2;
        this.homeworkForm.file = row.url;
        this.$refs.upload.clearFiles();
      });
    },
    deletePublishHomework(id) {
      const that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/common/deleteHomework.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadFinishPublishJob(that.page.currentPage);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 文件上传成功的钩子
    enclosureUploadSuccess(res, file) {
      this.homeworkForm.file = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.homeworkForm.file);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.homeworkForm.file = "";
    },
  },
};
</script>

<style scoped lang="scss">
.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
.load-file {
  margin-top: 5px;
}
</style>
