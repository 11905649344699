<template>
  <el-select v-model="selectedClass"
			 :style="marginLeft?'margin-left: 10px;':''"
			 :disabled="checkDisabled"
			 @change="selectClassChange"
			 placeholder="请选择学科">
	<el-option :label="cls.label"
			   :value="cls.value"
			   v-for="cls in classList"
			   :key="cls.id"></el-option>
  </el-select>

</template>

<script>
export default {
  name: "teacherClass",
  props: {
	//是否默认第一项
	defaultFirst: Boolean,
	//margin-left: 10px;
	marginLeft: Boolean,
	// 级联选择框是否禁用
	checkDisabled: Boolean,
	//初始值
	initialValue: {
	  type: String,
	  default: "",
	},
  },
  data() {
	return {
	  selectedClass: "",
	  classList: [],
	};
  },
  watch:{
	initialValue(){
	  console.log(this.initialValue)
	  this.selectedClass = this.initialValue;
	}
  },
  created() {
	this.loadTeacherClass();
	this.selectedClass = this.initialValue;
  },
  methods: {
	loadTeacherClass() {
	  const that = this;
	  this.$post("/media/queryMyClassSubject.do", {})
		.then((res) => {
		  res.data.forEach((cls) => {
			let className = {};
			className.value = `${cls.courseid}-${cls.classid}`;
			className.label = cls.course;
			that.classList.push(className);
		  });
		})
		.catch((err) => {
		  that.$message.error(err.message.data);
		});
	},
	selectClassChange(value) {
	  this.$emit("change", value || "-");
	},
  },
};
</script>

<style scoped>
</style>
